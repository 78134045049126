export const formFields = (fields, initial = {}) => {
  const form = {};
  const errors = {};
  for (const i in fields) {
    const field = fields[i];
    form[field] =
      initial && typeof initial[field] !== "undefined" ? initial[field] : "";
    errors[field] = [];
  }
  return { form, errors, loading: false };
};

export const youtubeParser = (url) => {
  var regExp =
    /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|shorts\/|watch\?v=)([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[1].length == 11 ? match[1] : false;
};

export const vimeoParser = (url) => {
  var firstPart = url.split("?")[0].split("/");
  return firstPart[firstPart.length - 1];
};

export function toHumanDate(date) {
  if (!date) return "";
  let [dt, tm] = date.split("T");
  dt = dt.split("-").reverse().join("/");
  tm = tm ? " " + tm.substr(0, 5) : "";
  return `${dt}${tm}`;
}

export function toComputerDate(date) {
  if (!date) return "";
  let [dt, tm] = date.split(" ");
  dt = dt.split("/").reverse().join("-");
  tm = tm ? "T" + tm.substr(0, 5) : "";
  return `${dt}${tm}`;
}
